<template>
  <!-- eslint-disable -->
  <main id="content" role="main">
    <section class="visual_intro">
        <div class="visual_wall"
          v-bind:style="{ backgroundImage: 'url(' + requireAssetsImg(mainbgImg) + ')' }">
        </div>  
        <div class="intro_title">
            <strong class="title">OPEN INNOVATION HUB</strong>
            <strong class="title">DREAMPLUS CENTER</strong>
        </div>
    </section>
    
    <section class="report_group">
        <!-- INNOVATION CENTERS -->
        <section class="report_box">
            <div class="box_title">
                <h2 class="title">INNOVATION CENTERS</h2>
                <p class="description" v-html="$t('content.studio.common.innovation_centers_desc')">                    
                </p>
            </div>
            <div class="global_center_area" data-aos="fade-up" data-aos-delay="500">
                <img :src="requireAssetsImg('studio/img_worldmap.png')" width="1059" height="588" class="img_map">
                <ul class="global_center_list">
                    <li class="center_item">
                        <p class="info">FINTECH STARTUP<br>INCUBATOR</p>
                        <p class="name" v-html="$t('content.studio.innovation_centers.center_63')"></p>
                    </li>
                    <li class="center_item">
                        <p class="info">OPEN INNOVATION CENTER<br>& ACCELERATOR</p>
                        <p class="name" v-html="$t('content.studio.innovation_centers.center_gangnam')">                           
                        </p>
                    </li>
                    <li class="center_item">
                        <p class="info">GLOBAL BRANCH<br>(JAPAN)</p>
                        <p class="name" v-html="$t('content.studio.innovation_centers.center_fukuoka')">                           
                        </p>
                    </li>
                    <li class="center_item">
                        <p class="info">GLOBAL BRANCH<br>(USA)</p>
                        <p class="name" v-html="$t('content.studio.innovation_centers.center_sanfrancisco')">                           
                        </p>
                    </li>
                    <li class="center_item">
                        <p class="info">GLOBAL BRANCH<br>(CHINA)</p>
                        <p class="name" v-html="$t('content.studio.innovation_centers.center_beijing')">                            
                        </p>
                    </li>
                </ul>
            </div>
        </section>
        <!-- // INNOVATION CENTERS -->

        <!-- DREAMPLUS 63 -->
        <section class="report_box">
            <div class="box_title">
                <h2 class="title">DREAMPLUS 63</h2>
                <p class="description" v-html="$t('content.studio.common.dreamplus_63_desc')">                    
                </p>
            </div>
            <img :src="requireAssetsImg('studio/img_dreamplus_63.png')" width="1168" height="540" class="img_center">

            <div class="member_swiper">
                <div class="swiper_controller">
                    <strong class="title">MEMBERS</strong>
                    <div class="swiper-fraction">
                        <a href="#" role="button" aria-label="이전" id="memberPrev"
                          class="swiper-fraction-prev"><div class="ico_prev"></div></a>
                        <div class="swiper-pagination-fraction">
                        </div>
                        <a href="#" role="button" aria-label="다음" id="memberNext"
                          class="swiper-fraction-next"><div class="ico_next"></div></a>
                    </div>
                </div>
                <div class="swiper-container">
                  <div class="member_list">
                    <StaggerTransition targets=".card-wrap">
                      <Swiper class="swiper-wrapper" :options="member_swiper">
                        <SwiperSlide class="swiper-slide">
                          <div class="card-wrap member" v-for="(member) in StudioMember[0]">
                            <div class="card">
                              <img :src="requireAssetsImg(member.img)"/>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide class="swiper-slide">
                          <div class="card-wrap member" v-for="(member) in StudioMember[1]">
                            <div class="card">
                              <img :src="requireAssetsImg(member.img)"/>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide class="swiper-slide">
                          <div class="card-wrap member" v-for="(member) in StudioMember[2]">
                            <div class="card">
                              <img :src="requireAssetsImg(member.img)"/>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </StaggerTransition>
                  </div>
                </div>
            </div>
            <div class="shortcut_area">
              <a href="#" role="button" class="btn_shortcut" @click="sixThreeGo"
                v-html="$t('content.studio.common.go_63')">
              </a>
            </div>
        </section>
        <!-- // DREAMPLUS 63 -->

        <!-- DREAMPLUS GANGNAM -->
        <section class="report_box">
            <div class="box_title">
                <h2 class="title">DREAMPLUS GANGNAM</h2>
                <p class="description" v-html="$t('content.studio.common.dreamplus_gangnam_desc')">                    
                </p>
            </div>
            <img :src="requireAssetsImg('studio/img_dreamplus_gangnam.png')" width="1168" height="540" class="img_center">
            <div class="member_swiper">
                <div class="swiper_controller">
                    <strong class="title">MEMBERS</strong>
                    <div class="swiper-fraction">
                        <a href="#" role="button" aria-label="이전" id="gangnamPrev"
                          class="swiper-fraction-prev"><div class="ico_prev"></div></a>
                        <div class="swiper-pagination-fraction1"></div>
                        <a href="#" role="button" aria-label="다음" id="gangnamNext"
                        class="swiper-fraction-next"><div class="ico_next"></div></a>
                    </div>
                </div>
                <div class="swiper-container">
                  <div class="member_list">
                    <StaggerTransition targets=".card-wrap">
                      <Swiper class="swiper-wrapper" :options="gangnam_swiper">
                          <SwiperSlide class="swiper-slide">
                            <div class="card-wrap member" v-for="(gangnam) in StudioMember[3]">
                              <div class="card">
                                <img :src="requireAssetsImg(gangnam.img)"/>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide class="swiper-slide">
                            <div class="card-wrap member" v-for="(gangnam) in StudioMember[4]">
                              <div class="card">
                                <img :src="requireAssetsImg(gangnam.img)"/>
                              </div>
                            </div>
                          </SwiperSlide>
                      </Swiper>
                    </StaggerTransition>
                  </div>
                </div>
            </div>
            <div class="shortcut_area">
              <a href="#" role="button" class="btn_shortcut" @click="ganGnamGo"
                v-html="$t('content.studio.common.go_gangnam')">
              </a>
            </div>
        </section>
        <!-- // DREAMPLUS GANGNAM -->

        <!-- DREAMPLUS GLOBAL -->
        <section class="report_box">
            <div class="box_title">
                <h2 class="title">DREAMPLUS GLOBAL</h2>
                <p class="description" v-html="$t('content.studio.common.dreamplus_global_desc')">                    
                </p>
            </div>
            <div class="overseas_center_list" data-aos="fade-up" data-aos-delay="500">
                <div class="overseas_center">
                    <img :src="requireAssetsImg('studio/img_dreamplus_sanfrancisco.png')" width="372" height="390">
                    <strong class="name">SAN FRANCISCO</strong>
                    <p class="description" v-html="$t('content.studio.global_centers.senfrancisco')">                        
                    </p>
                </div>
                <div class="overseas_center">
                    <img :src="requireAssetsImg('studio/img_dreamplus_fukuoka.png')" width="372" height="390">
                    <strong class="name">FUKUOKA</strong>
                    <p class="description" v-html="$t('content.studio.global_centers.fukuoka')">                        
                    </p>
                </div>
                <div class="overseas_center">
                    <img :src="requireAssetsImg('studio/img_dreamplus_beijing.png')" width="372" height="390">
                    <strong class="name">BEIJING</strong>
                    <p class="description" v-html="$t('content.studio.global_centers.beijing')">                        
                    </p>
                </div>
            </div>
            <div class="shortcut_area">
                <router-link :to="{ name: 'startup' }" role="button" class="btn_shortcut"
                v-html="$t('content.studio.common.go_k_startup')">
                </router-link>
            </div>
        </section>
        <!-- // DREAMPLUS GLOBAL -->

    </section>
</main>

</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import StudioMember from '@/views/dreamplus/StudioMember';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';

export default {
  name: 'Studio',
  mixins: [
    mixinHelperUtils,
  ],
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    StaggerTransition,
  },
  mounted() {
    const elVisual = document.getElementsByClassName('visual_intro');
    elVisual[0].className += ' ani_intro';
  },
  data() {
    return {
      StudioMember,
      mainbgImg: 'studio/img_dreamplus_center.png',
      member_swiper: {
        slidesPerView: 1,
        loop: false,
        pagination: {
          el: '.swiper-pagination-fraction',
          type: 'fraction',
        },
        navigation: {
          nextEl: '#memberNext',
          prevEl: '#memberPrev',
        },
      },
      gangnam_swiper: {
        slidesPerView: 1,
        loop: false,
        pagination: {
          el: '.swiper-pagination-fraction1',
          type: 'fraction',
        },
        navigation: {
          nextEl: '#gangnamNext',
          prevEl: '#gangnamPrev',
        },
      },
    };
  },
  methods: {
    ganGnamGo() {
      window.open(`${process.env.VUE_APP_PORTAL}`);
    },
    sixThreeGo() {
      window.open(`${process.env.VUE_APP_63}`);
    },
  },
};
</script>
