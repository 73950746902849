export default {
  0: [
    {
      img: 'studio/63fintech/logo_63_member01.png',
      title: '보맵',
    },
    {
      img: 'studio/63fintech/logo_63_member02.png',
      title: '센스톤',
    },
    {
      img: 'studio/63fintech/logo_63_member03.png',
      title: '애자일소다',
    },
    {
      img: 'studio/63fintech/logo_63_member04.png',
      title: '딥서치',
    },
    {
      img: 'studio/63fintech/logo_63_member05.png',
      title: '센트비',
    },
    {
      img: 'studio/63fintech/logo_63_member06.png',
      title: '와디즈',
    },
    {
      img: 'studio/63fintech/logo_63_member07.png',
      title: 'SBCN',
    },
    {
      img: 'studio/63fintech/logo_63_member08.png',
      title: '콴텍',
    },
    {
      img: 'studio/63fintech/logo_63_member09.png',
      title: '두물머리',
    },
    {
      img: 'studio/63fintech/logo_63_member10.png',
      title: '레지',
    },
    {
      img: 'studio/63fintech/logo_63_member11.png',
      title: '후즈굿',
    },
    {
      img: 'studio/63fintech/logo_63_member12.png',
      title: '블루바이저',
    },
    {
      img: 'studio/63fintech/logo_63_member13.png',
      title: '지바이크',
    },
    {
      img: 'studio/63fintech/logo_63_member14.png',
      title: '웰그램',
    },
    {
      img: 'studio/63fintech/logo_63_member15.png',
      title: '센티언스',
    },
  ],
  1: [
    {
      img: 'studio/63fintech/logo_63_member16.png',
      title: '에몰라이즈',
    },
    {
      img: 'studio/63fintech/logo_63_member17.png',
      title: '핀다',
    },
    {
      img: 'studio/63fintech/logo_63_member18.png',
      title: '휴먼스케이프',
    },
    {
      img: 'studio/63fintech/logo_63_member19.png',
      title: '럭스보로',
    },
    {
      img: 'studio/63fintech/logo_63_member20.png',
      title: '텐큐브',
    },
    {
      img: 'studio/63fintech/logo_63_member21.png',
      title: '핀테크',
    },
    {
      img: 'studio/63fintech/logo_63_member22.png',
      title: '콰라',
    },
    {
      img: 'studio/63fintech/logo_63_member23.png',
      title: '런인베스트',
    },
    {
      img: 'studio/63fintech/logo_63_member24.png',
      title: '인텔리퀀트',
    },
    {
      img: 'studio/63fintech/logo_63_member25.png',
      title: '위드펀드',
    },
    {
      img: 'studio/63fintech/logo_63_member26.png',
      title: '스왈라비',
    },
    {
      img: 'studio/63fintech/logo_63_member27.png',
      title: '데이터유니버스',
    },
    {
      img: 'studio/63fintech/logo_63_member28.png',
      title: '탱커',
    },
    {
      img: 'studio/63fintech/logo_63_member29.png',
      title: '해치랩스',
    },
    {
      img: 'studio/63fintech/logo_63_member30.png',
      title: '히든트랙',
    },
  ],
  2: [
    {
      img: 'studio/63fintech/logo_63_member31.png',
      title: '레터플라이',
    },
    {
      img: 'studio/63fintech/logo_63_member32.png',
      title: '집펀드',
    },
    {
      img: 'studio/63fintech/logo_63_member33.png',
      title: '해빗팩토리',
    },
    {
      img: 'studio/63fintech/logo_63_member34.png',
      title: 'TNDN',
    },
    {
      img: 'studio/63fintech/logo_63_member35.png',
      title: '헤이비트',
    },
    {
      img: 'studio/63fintech/logo_63_member36.png',
      title: '히든트랙',
    },
    {
      img: 'studio/63fintech/logo_63_member37.png',
      title: '마인드셋',
    },
    {
      img: 'studio/63fintech/logo_63_member38.png',
      title: 'FMSOFT',
    },
  ],
  3: [
    {
      img: 'studio/gangnam/logo_gangnam_member01.png',
      title: '보맵',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member02.png',
      title: '드림어스',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member03.png',
      title: '디라이트',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member04.png',
      title: '삼분의일',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member05.png',
      title: '라이클',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member06.png',
      title: '코리아스타트포럼',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member07.png',
      title: '아파트너',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member08.png',
      title: '보이스루',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member09.png',
      title: '팀윙크',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member10.png',
      title: '리버스랩',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member11.png',
      title: '센트비',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member12.png',
      title: '씨엘오',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member13.png',
      title: '팔루썸니',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member14.png',
      title: '큐엠아이티',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member15.png',
      title: '웰트',
    },
  ],
  4: [
    {
      img: 'studio/gangnam/logo_gangnam_member16.png',
      title: '레이즈지엘에스',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member17.png',
      title: '웰리시스',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member18.png',
      title: '비주얼',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member19.png',
      title: '식스샵',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member20.png',
      title: '윙크스톤파트너스',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member21.png',
      title: '럭스보로',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member22.png',
      title: '모니터코퍼레이션',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member23.png',
      title: '디자이노블',
    },
    {
      img: 'studio/gangnam/logo_gangnam_member24.png',
      title: '소프트베리',
    },
  ],
};
